<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("equipment_types") }}</h1>
      <button
        v-if="role != 99"
        @click="open = true"
        class="bg-green-500 px-3 rounded text-gray-200"
      >
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
    </div>
    <div class="flex">
      <div class="flex-1 overflow-hidden">
        <el-table
          :data="equipmentTypes"
          border
          v-loading="loading"
          style="width: 100%"
          class="rounded-lg shadow-md"
        >
          <el-table-column fixed prop="id" label="ID" width="60">
          </el-table-column>
          <el-table-column
            fixed
            prop="name"
            :label="$t('name')"
            min-width="180"
          >
          </el-table-column>
          <el-table-column
            v-if="role != 99"
            :label="$t('actions')"
            fixed="right"
            :min-width="actionsWidth"
          >
            <template slot-scope="scope">
              <el-button size="mini" @click="openEdit(scope.row.id)">
                <i class="el-icon-edit"></i>
                <span class="hidden lg:inline-block">{{ $t("edit") }}</span>
              </el-button>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t('no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('you_want_to_delete')"
                class="ml-2"
                @confirm="equipmentTypeDelete(scope.row.id)"
              >
                <el-button slot="reference" size="mini" type="danger">
                  <i class="el-icon-delete"></i>
                  <span class="hidden lg:inline-block">{{ $t("delete") }}</span>
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="mt-3 flex justify-end">
          <el-pagination
            background
            layout="prev, pager, next"
            hide-on-single-page
            @prev-click="Prev"
            @next-click="Next"
            @current-change="handleCurrentChange"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>

      <el-drawer
        :visible.sync="open"
        :with-header="false"
        size="400px"
        @close="CloseDrawer"
        v-if="role != 99"
      >
        <div
          class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl"
        >
          <p>EXSERVICE</p>
          <button @click="open = false" class="px-2">
            <i class="el-icon-close"></i>
          </button>
        </div>
        <div class="p-4">
          <h1 class="mb-4">{{ $t("add_equipment_type") }}</h1>
          <el-form
            ref="equipment_type"
            label-position="top"
            :model="equipment_type"
            @submit.prevent.native
          >
            <el-form-item
              prop="name"
              :label="$t('name')"
              :rules="{
                required: true,
                message: $t('please_input'),
                trigger: 'blur',
              }"
            >
              <el-input
                :placeholder="$t('input')"
                v-model="equipment_type.name"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-form>
          <div class="text-right">
            <el-button
              v-if="!edit"
              @click="addEquipmentType"
              type="primary"
              :loading="loading"
            >
              {{ $t("add") }}
            </el-button>
            <el-button
              v-else
              @click="editEquipmentType"
              type="primary"
              :loading="loading"
            >
              {{ $t("save") }}
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "equipmentType",
  data() {
    return {
      actionsWidth: 270,
      open: false,
      edit: false,
      id: "",
      equipment_type: {
        name: "",
      },
      equipmentTypes: [],
      totalCount: 0,
      count: 1,
      loading: false,
      role: localStorage.role,
    };
  },
  created() {
    this.getEquipmentTypes();
    window.innerWidth < 992 ? (this.actionsWidth = 120) : "";
  },
  methods: {
    async getEquipmentTypes() {
      this.loading = true;
      await axios
        .get(`equipment_types/${this.count === 1 ? "" : "?page=" + this.count}`)
        .then((response) => {
          this.totalCount = response.data.count;
          this.equipmentTypes = response.data.results;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Prev() {
      this.count--;
      this.getEquipmentTypes();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getEquipmentTypes();
    },
    Next() {
      this.count++;
      this.getEquipmentTypes();
    },
    openEdit(id) {
      this.open = true;
      this.edit = true;
      this.id = id;
      this.equipment_type.name = this.equipmentTypes.find(
        (data) => data.id === id
      ).name;
    },
    CloseDrawer() {
      this.edit = false;
      this.equipment_type.name = "";
      this.$refs.equipment_type.clearValidate();
    },
    editEquipmentType() {
      this.$refs.equipment_type.validate((valid) => {
        if (valid) {
          this.loading = true;
          axios
            .patch(`equipment_types/${this.id}/`, this.equipment_type)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getEquipmentTypes();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
    addEquipmentType() {
      this.$refs.equipment_type.validate((valid) => {
        if (valid) {
          this.loading = true;
          axios
            .post("equipment_types/", this.equipment_type)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getEquipmentTypes();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    async equipmentTypeDelete(id) {
      this.loading = true;
      await axios
        .delete(`equipment_types/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getEquipmentTypes();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
